export const SET_LOADING = "setLoading";

// mutation types

export default {
  state: {
    loading: false
  },
  getters: {
    loading(state) {
      return state.loading;
    }
  },
  actions: {
    [SET_LOADING](state, payload) {
      state.commit(SET_LOADING, payload);
    }
  },
  mutations: {
    [SET_LOADING](state, payload) {
      state.loading = payload;
    }
  }
};
