import ApiService from "@/common/api.service";

// action type
export const GET_LIST_COURSE = "/course";
export const DETAIL_COURSE = "/course-detail";
export const SAVE_COURSE = "/create-course";
export const UPDATE_COURSE = "/update/course";
export const DELETE_COURSE = "/course/delete";
export const SET_LIST_COURSE = "setListCourse";
export const SET_DETAIL_COURSE = "setDetailTeeacher";

export const SET_ERROR = "setError";

const state = {
    errors: null,
    courses: [],
    course: {},
};

const getters = {
    listCourses(state) {
        return state.courses;
    },
    detailCourse(state) {
        return state.course;
    }
};

const actions = {
    [GET_LIST_COURSE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(GET_LIST_COURSE, payload)
                .then(( data ) => {
                    context.commit(SET_LIST_COURSE, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response);
                    reject(response);
                });
        });
    },
    [DETAIL_COURSE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get(DETAIL_COURSE, payload)
                .then(( data ) => {
                    context.commit(SET_DETAIL_COURSE, data.data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response);
                    reject(response);
                });
        });
    },

    [SAVE_COURSE](context,payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(SAVE_COURSE, payload)
                .then(( data ) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.errors);
                    reject(response);
                });
        });
    },

    [UPDATE_COURSE](context,payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${UPDATE_COURSE}/${payload.id}`, payload)
                .then(( data ) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.errors);
                    reject(response);
                });
        });
    },

    [DELETE_COURSE](context,payload) {
        return new Promise((resolve, reject) => {
            ApiService.delete(DELETE_COURSE, payload)
                .then(( data ) => {
                    resolve(data);
                  })
                  .catch(({ response }) => {
                    context.commit(SET_ERROR, response);
                    reject(response);
                  });
        });
    },
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_LIST_COURSE](state, data) {
        state.courses = data;
        state.errors = {};
    } ,
    [SET_DETAIL_COURSE](state, data) {
        state.course = data;
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
