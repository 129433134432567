import ApiService from "@/common/api.service";

// action types
export const GET_LIST_CONFIG = "/config/list";
export const SAVE_CONFIG = "/config/save";

// mutation types
export const SET_CONFIG = "setConfig";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  configs: [],
  checks: []
};

const getters = {
  listConfig(state) {
    return state.configs;
  }
};

const actions = {
  [GET_LIST_CONFIG](context) {
    return new Promise(resolve => {
      ApiService.get(GET_LIST_CONFIG)
        .then(({ data }) => {
          if (data.status) {
            context.commit(SET_CONFIG, data);
          } else {
            context.commit(SET_ERROR, data.message);
          }
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [SAVE_CONFIG](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post(SAVE_CONFIG, credentials)
        .then(({ data }) => {
          context.commit(SET_CONFIG, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },

  [SET_CONFIG](state, data) {
    state.configs = data.data;
    state.errors = data.message;
    state.checks = data.status;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
