import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
    routes: [
        {
            path: "/",
            redirect: "/dashboard",
            component: () => import("@/views/theme/Base"),
            children: [
                {
                    path: "/dashboard",
                    name: "dashboard",
                    component: () => import("@/views/pages/Dashboard.vue")
                },
                {
                    path: "/user",
                    name: "user",
                    component: () => import("@/views/pages/user/User.vue")
                },
                {
                    path: "/user/edit/:id",
                    name: "user-detail",
                    component: () => import("@/views/pages/user/Detail.vue")
                },
                {
                    path: "/user-experience",
                    name: "user-experience",
                    component: () => import("@/views/pages/user/UserExperience.vue")
                },
                {
                    path: "/user-tutor",
                    name: "user-tutor",
                    component: () => import("@/views/pages/user/tutor/UserTutor.vue")
                },
                {
                    path: "/user-tutor/add",
                    name: "user-tutor-add",
                    component: () => import("@/views/pages/user/tutor/DetailTutor.vue")
                },
                {
                    path: "/user-tutor/:id",
                    name: "user-tutor-edit",
                    component: () => import("@/views/pages/user/tutor/DetailTutor.vue")
                },
                {
                    path: "/user/add",
                    name: "user-add",
                    component: () => import("@/views/pages/user/Detail.vue")
                },
                {
                    path: "/Setting",
                    name: "setting",
                    component: () => import("@/views/pages/Setting.vue")
                },
                {
                    path: "/subject",
                    name: "subject",
                    component: () => import("@/views/pages/subject/SubjectList.vue")
                },
                {
                    path: "/subject",
                    name: "subject-detail",
                    component: () => import("@/views/pages/subject/SubjectDetail.vue")
                },
                {
                    path: "/subject/add",
                    name: "subject-add",
                    component: () => import("@/views/pages/subject/SubjectDetail.vue")
                },
                {
                    path: "/grade/list",
                    name: "grade",
                    component: () => import("@/views/pages/class/GradeList.vue")
                },
                {
                    path: "/grade",
                    name: "grade-detail",
                    component: () => import("@/views/pages/class/GradeDetail.vue")
                },
                {
                    path: "/lesson",
                    name: "lesson",
                    component: () => import("@/views/pages/lesson/Lesson.vue")
                },


                {
                    path: "/ask",
                    name: "ask",
                    component: () => import("@/views/pages/ask/List.vue")
                },
                {
                    path: "/ask/detail",
                    name: "ask-detail",
                    component: () => import("@/views/pages/ask/Detail.vue")
                },
                {
                    path: "/ask/edit/:slug",
                    name: "ask-edit",
                    component: () => import("@/views/pages/ask/Detail.vue")
                },

                {
                    path: "/answer",
                    name: "answer",
                    component: () => import("@/views/pages/answer/List.vue")
                },
                {
                    path: "/answer/detail",
                    name: "answer-detail",
                    component: () => import("@/views/pages/answer/Detail.vue")
                },
                {
                    path: "/answer/edit/:id",
                    name: "answer-edit",
                    component: () => import("@/views/pages/answer/Detail.vue")
                },
                {
                    path: "/dailyQuestion",
                    name: "dailyQuestion",
                    component: () => import("@/views/pages/dailyQuestion/List.vue")
                },
                {
                    path: "/dailyQuestion/edit/:slug",
                    name: "dailyQuestion-edit",
                    component: () => import("@/views/pages/dailyQuestion/Detail.vue")
                },
                {
                    path: "/dailyQuestion/add",
                    name: "dailyQuestion-add",
                    component: () => import("@/views/pages/dailyQuestion/Detail.vue")
                },

                {
                    path: "/notification",
                    name: "notification",
                    component: () => import("@/views/pages/notification/List.vue")
                },
                {
                    path: "/report",
                    name: "report",
                    component: () => import("@/views/pages/report/List.vue")
                },

                {
                    path: "/lesson/edit/:slug",
                    name: "lession-detail",
                    component: () => import("@/views/pages/lesson/Detail.vue")
                },
                {
                    path: "/lesson/add",
                    name: "lession-add",
                    component: () => import("@/views/pages/lesson/Detail.vue")
                },

                {
                    path: "/news-category",
                    name: "news-category",
                    component: () => import("@/views/pages/category/NewsCategory.vue")
                },
                {
                    path: "/news-category/edit/:slug",
                    name: "category-detail",
                    component: () => import("@/views/pages/category/CategoryDetail.vue")
                },
                {
                    path: "/news-category/add",
                    name: "category-add",
                    component: () => import("@/views/pages/category/CategoryDetail.vue")
                },
                {
                    path: "/config",
                    name: "config",
                    component: () => import("@/views/pages/Config.vue")
                },
                {
                    path: "/gift",
                    name: "gift",
                    component: () => import("@/views/pages/settingNews/gift.vue")
                },
                {
                    path: "/introduce",
                    name: "introduce",
                    component: () => import("@/views/pages/settingNews/introduce.vue")
                },
                {
                    path: "/policy",
                    name: "policy",
                    component: () => import("@/views/pages/settingNews/policy.vue")
                },
                {
                    path: "/rules",
                    name: "rules",
                    component: () => import("@/views/pages/settingNews/rules.vue")
                },
                {
                    path: "/menu-footer",
                    name: "menu-footer",
                    component: () => import("@/views/pages/menu-footer/List.vue")
                },
                {
                    path: "/add-menu-footer",
                    name: "detail-footer",
                    component: () => import("@/views/pages/menu-footer/Detail.vue")
                },
                {
                    path: "/teacher",
                    name: "teacher",
                    component: () => import("@/views/pages/teacher/list.vue")
                },
                {
                    path: "/add-teacher",
                    name: "detail-teacher",
                    component: () => import("@/views/pages/teacher/detail.vue")
                },
                {
                    path: "/media-press",
                    name: "media-press",
                    component: () => import("@/views/pages/media-press/list.vue")
                },
                {
                    path: "/add-media-press",
                    name: "detail-media-press",
                    component: () => import("@/views/pages/media-press/detail.vue")
                },
                {
                    path: "/course",
                    name: "course",
                    component: () => import("@/views/pages/course/list.vue")
                },
                {
                    path: "/add-course",
                    name: "detail-course",
                    component: () => import("@/views/pages/course/detail.vue")
                },
            ]
        },

        {
            path: "/error",
            name: "error",
            component: () => import("@/views/pages/error/Error.vue"),
            children: [
                {
                    path: "error-1",
                    name: "error-1",
                    component: () => import("@/views/pages/error/Error-1.vue")
                },
                {
                    path: "error-2",
                    name: "error-2",
                    component: () => import("@/views/pages/error/Error-2.vue")
                },
                {
                    path: "error-3",
                    name: "error-3",
                    component: () => import("@/views/pages/error/Error-3.vue")
                },
                {
                    path: "error-4",
                    name: "error-4",
                    component: () => import("@/views/pages/error/Error-4.vue")
                },
                {
                    path: "error-5",
                    name: "error-5",
                    component: () => import("@/views/pages/error/Error-5.vue")
                },
                {
                    path: "error-6",
                    name: "error-6",
                    component: () => import("@/views/pages/error/Error-6.vue")
                }
            ]
        },
        {
            path: "/",
            component: () => import("@/views/pages/auth/Auth"),
            children: [
                {
                    name: "login",
                    path: "/login",
                    component: () => import("@/views/pages/auth/Login")
                }
                // {
                //   name: "register",
                //   path: "/register",
                //   component: () => import("@/views/pages/auth/Register")
                // }
            ]
        },
        {
            path: "*",
            redirect: "/404"
        },
        {
            // the 404 route, when none of the above matches
            path: "/404",
            name: "404",
            component: () => import("@/views/pages/error/Error-1.vue")
        }
    ]
});
