const ID_TOKEN_KEY = "id_token";
const ID_AUTHORITY_KEY = "authority";
const ID_FULLNAME_KEY = "full_name";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const getAuthority = () => {
  return window.localStorage.getItem(ID_AUTHORITY_KEY);
};

export const saveAuthority = authority => {
  window.localStorage.setItem(ID_AUTHORITY_KEY, authority);
};

export const destroyAuthority = () => {
  window.localStorage.removeItem(ID_AUTHORITY_KEY);
};

export const getFullName = () => {
  return window.localStorage.getItem(ID_FULLNAME_KEY);
};

export const saveFullName = fullName => {
  window.localStorage.setItem(ID_FULLNAME_KEY, fullName);
};

export const destroyFullName = () => {
  window.localStorage.removeItem(ID_FULLNAME_KEY);
};

export default { getToken, saveToken, destroyToken, getAuthority, saveAuthority, destroyAuthority, getFullName, saveFullName, destroyFullName};
