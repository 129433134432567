import { extend } from "vee-validate";
import { required,email } from "vee-validate/dist/rules";
import { ValidationObserver, ValidationProvider } from "vee-validate";

import Vue from "vue";
//add default rules
extend("required", {
  ...required,
  message: "Trường này không được bỏ trống"
});
extend("email", {
  ...email,
  message: "The email is invalid"
});

//add custom rules
extend('minmax', {
  validate(value, { min, max }) {
    return value.length >= min && value.length <= max;
  },
  params: ['min', 'max'],
  message:"mô tả quá dài"
});
extend('number',{
  validate(value) {
    // Custom regex for number 
    var reg = /^\d+$/;
    // Check for either of these to return true
    return reg.test(value);
  },
  message:"Trường này phải là chữ sô"
});
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);